import React, { useEffect } from "react";
import Tracker from "@openreplay/tracker/cjs";
import { Widget } from "@typeform/embed-react";

// images
import BenefitIcon01 from "../../images/benefit-icon-01.svg";
import BenefitIcon02 from "../../images/benefit-icon-02.svg";
import BenefitIcon03 from "../../images/benefit-icon-03.svg";
import FormArticleBg from "../../images/form-article-bg.webp";
import AuthorityImg from "../../images/form-bernardo-hero.webp";

// components
import Main from "../../components/main";
import Header from "../../components/header";
import Seo from "../../components/seo";
import Footer from "../../components/footer";

// styles
import * as SC from "../../styles/formType";

// open replay
const tracker = new Tracker({ projectKey: "mJk2kHRhU2x0UP887beO", __DISABLE_SECURE_MODE: true });

// default
function FormNewComponent() {
  const isSSR = typeof window === "undefined";

  useEffect(() => {
    if (!isSSR) {
      tracker.start();
    }
  }, []);

  return (
    <>
      <Seo title="| Formulário Mentoria" />
      <Main className="z-30">
        <Header blueDefault />

        <SC.HeaderSpacer />

        <img src={FormArticleBg} alt="Pensamental - Fundo BG" style={{ display: "none" }} />

        <SC.FormArticle>
          <SC.ContentInfo>
            <Widget id="LOY83oi1" style={{ width: "100%", height: "650px" }} enableSandbox />
          </SC.ContentInfo>
        </SC.FormArticle>

        <article className="w-full flex flex-col text-center items-center bg-blue-pensamental-light p-10 ">
          <h2 className="max-w-screen-xl font-baloo text-4xl text-white text-left text-center mb-0 md:mb-6 leading-7">O que você irá aprender com o bônus:</h2>

          <ul className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3">
            <li className="block py-0 md:py-0">
              <div className="pl-0 pr-2 py-4 md:py-0 flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-center border-b lg:border-b-0 border-r-0 lg:border-r">
                <SC.BenefitListItemIcon width="150" height="150" alt="Controlar a mente" src={BenefitIcon02} />
                <SC.BenefitListItemInfo>
                  <h3 className="font-baloo text-xl text-white xs:text-center sm:text-center md:text-left lg:text-left">Controlar a mente</h3>
                  <span className="mt-2 font-comfortaa text-sm text-white xs:text-center sm:text-center md:text-left lg:text-left">
                    Te ensinamos a controlar melhor suas emoções, pensamentos e comportamentos. Isso te torna capaz de solucionar a dificuldade que está vivendo sem precisar esperar anos pela sua
                    resposta.
                  </span>
                </SC.BenefitListItemInfo>
              </div>
            </li>

            <li className="block py-0 md:py-0">
              <div className="pl-0 pr-2 py-4 md:py-0 flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-center border-b lg:border-b-0 border-r-0 lg:border-r">
                <SC.BenefitListItemIcon width="150" height="150" alt="Aumentar a autoconfiança" src={BenefitIcon01} />

                <SC.BenefitListItemInfo>
                  <h3 className="font-baloo text-xl text-white xs:text-center sm:text-center md:text-left lg:text-left">Aumentar a autoconfiança</h3>
                  <span className="mt-2 font-comfortaa text-sm text-white xs:text-center sm:text-center md:text-left lg:text-left">
                    Quando nos deparamos com situações difíceis e não conseguimos viver como gostaríamos, a nossa autoconfiança vai lá pra baixo, não é mesmo? Nós vamos te ajudar para que voce mesma
                    consiga se reerguer novamente.
                  </span>
                </SC.BenefitListItemInfo>
              </div>
            </li>

            <li className="block py-0 md:py-0">
              <div className="pl-0 pr-2 py-4 md:py-0 flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-center">
                <SC.BenefitListItemIcon width="150" height="150" alt="Reconstruir a identidade verdadeira" src={BenefitIcon03} />
                <SC.BenefitListItemInfo>
                  <h3 className="font-baloo text-xl text-white xs:text-center sm:text-center md:text-left lg:text-left">Reconstruir a identidade verdadeira</h3>
                  <span className="mt-2 font-comfortaa text-sm text-white xs:text-center sm:text-center md:text-left lg:text-left">
                    Muitos problemas da vida quebram a sua identidade e fazem você agir contra a sua vontade. Junto de nós, você irá se reconstruir de forma assertiva e autêntica para ser e viver como
                    você sempre quis.
                  </span>
                </SC.BenefitListItemInfo>
              </div>
            </li>
          </ul>
        </article>

        <SC.AuthorityArticle>
          <div className="flex flex-col xl:flex-row items-center justify-center max-w-full xl:max-w-screen-xl">
            <div className="relative w-full sm:w-3/5 md:w-2/5">
              <div style={{ top: "-20px", left: "-20px" }} className="absolute h-full rounded-md border-5 border-blue-pensamental-light w-full transform scale-90 z-5" />
              <img
                className="rounded-md border-5 border-blue-pensamental-darkest bg-center bg-cover bg-repeat transform scale-90 z-0"
                loading="lazy"
                alt="Construa uma vida que vale a pena viver"
                width="600"
                src={AuthorityImg}
              />
            </div>

            <div className="flex flex-col p-6 w-full md:w-3/5 text-center md:text-left">
              <p className="w-full py-2 font-comfortaa text-md text-gray-500">
                Bernardo Tietze é mentor de Saúde Mental e Desenvolvimento Humano, já tendo vencido a depressão, a ansiedade social, a baixa autoestima, a baixa autoconfiança e inúmeros desafios
                emocionais ao longo de sua trajetória tanto pessoal quanto profissional. Palestrante em instituições de ensino e empresas com foco no crescimento humano e inteligência emocional,
                atuando na promoção de saúde mental e posicionamento comunicativo para pessoas que buscam alta performance.
              </p>

              <p className="w-full font-comfortaa font-black text-md text-blue-pensamental-dark">#COMUNIDADEPENSAMENTAL</p>
            </div>
          </div>
        </SC.AuthorityArticle>

        <Footer hideFooter isDarkest />
      </Main>
    </>
  );
}

export default FormNewComponent;
